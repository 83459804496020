<template>
  <div>
    <OfferProfileCard @updateOffer="loadOfferData"/>
    <BaseDocumentationCard
      v-model="documentation"
      :resource="offer"
      :observations="observations"
      :delete-end-point="deleteEndPoint"
      :update-end-point="updateEndPoint"
    />
    <OfferLinesCard />
    <BaseCommentCard :filters="commentsFilters" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import OfferProfileCard from '@/components/offers/card/OfferProfileCard.vue'
import OfferLinesCard from '@/components/offers/card/OfferLinesCard.vue'
import BaseDocumentationCard from '@/components/ui/card/BaseDocumentationCard.vue'
import BaseCommentCard from '@/components/ui/card/BaseCommentCard.vue'

export default {
  components: {
    BaseCommentCard,
    BaseDocumentationCard,
    OfferLinesCard,
    OfferProfileCard,
  },
  computed: {
    ...mapGetters('offer', {
      offer: 'getOffer',
    }),
    commentsFilters() {
      return { offer_id: this.$route.params.id }
    },
    observations() {
      return this.offer?.observations || 'Sin observaciones'
    },
    documentation: {
      get() {
        return this.offer?.documentation || []
      },
      set(value) {
        this.setOfferDocumentation(value)
      },
    },
    deleteEndPoint() {
      return this.offer ? `/offers/delete_doc/${this.offer.id}` : null
    },
    updateEndPoint() {
      return this.offer ? `/offers/${this.offer.id}` : null
    },
  },
  methods: {
    ...mapActions('app', ['setLoading']),
    ...mapActions('offer', ['loadOffer', 'setOfferDocumentation']),
    async loadOfferData() {
      try {
        this.setLoading(true)
        await this.loadOffer(this.$route.params.id)
      } finally {
        this.setLoading(false)
      }
    },
  },
  mounted() {
    this.loadOfferData()
  },
}
</script>

<style scoped>
</style>
